.page-container {
    display: flex;
    flex-direction: column;
}

.page-bg {
    background: url("assets/images/background_new.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    /* animation: bgAnimate 30s linear infinite alternate; */
}

#adjust {
    width: 100%
}

.page-section {
    display: table;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    /* background-color: #1b1919ad; */
    z-index: 2;
    align-items: center;
}

.limited-showcase-section {
    /* height: 100vh; */
    /* background-color: #1b1919ad; */
    z-index: 2;
    margin-top: 200px;
}

@keyframes bgAnimate {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.2);
    }
}

.section-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0 24rem;
    height: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.mint-section-title {
    font-size: 64px;
    margin-bottom: 24px;
    font-weight: 800;
}

.title-center {
    align-self: center;
}

.mint-desc-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
    margin-bottom: 50px;
    margin-top: 30px;
}

.mint-desc-container {
    display: flex;
    flex-direction: column;
    padding: 22px 40px;
    border-radius: 16px;
    border: 1px solid rgb(128 128 128 / 57%);
    box-shadow: 0 0 0px 2px rgb(128 128 128 / 20%);
}

.mint-desc-container:hover>.mint-desc-heading {
    opacity: 0.5;
}

.mint-desc-container:hover>.mint-desc-value {
    opacity: 0.5;
}

.mint-desc-heading {
    color: #ffffff;
    font-size: 16px;
}

.mint-desc-value {
    color: #ffffff;
    font-size: 42px;
}

.wallet-address {
    color: #ffffff;
    font-size: 28px;
}

.mint-row-center {
    display: flex;
    align-self: center;
    justify-content: center;
}

.connect-wallet-btn {
    border-radius: 20px !important;
    font-size: 18px !important;
    padding: 8px 20px !important;
    background-color: #6f29a5 !important;
    font-family: "Source Sans Pro" !important;
    font-weight: 600 !important;
    color: white !important;
}

.mint-btn {
    border-radius: 7px !important;
    font-size: 18px !important;
    padding: 4px 20px !important;
    background-color: #ffffff !important;
    font-family: "Source Sans Pro" !important;
    font-weight: 600 !important;
    color: #000000ad !important;
    width: 240px;
}

.slider-desc {
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 15px;
}

.mint-slider {
    margin-right: 30px;
    padding-top: 20px;
    width: 100%;
    margin-bottom: 20px;
}

.mint-slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider-css>.MuiSlider-thumb>.MuiSlider-valueLabel {
    background-color: #ffffff !important;
    color: #26282d !important;
    font-weight: 600 !important;
}

.slider-css>.MuiSlider-markLabel {
    color: rgb(255 255 255 / 78%) !important;
}

.slider-css>.MuiSlider-markLabelActive {
    color: rgb(255 255 255 / 87%) !important;
}

.info-row {
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 80px;
}

.info-icon {}

.info-desc {
    font-size: 14px;
    margin-left: 5px;
}

@media (min-width: 320px) and (max-width: 568px) {
    .section-content {
        padding: 0 3rem;
    }
    .mint-section-title {
        font-size: 30px;
        margin-bottom: 16px;
        text-align: center;
    }
    .wallet-connected-title {
        margin-top: 250px;
    }
    .mint-desc-grid {
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 15px;
        margin-bottom: 30px;
    }
    .mint-desc-container {
        padding: 15px 25px;
    }
    .mint-desc-heading {
        font-size: 14px;
    }
    .mint-desc-value {
        font-size: 28px;
    }
    .slider-desc {
        font-size: 18px;
        margin-bottom: 15px;
        text-align: center;
    }
    .mint-slider {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
    }
    .mint-slider-container {
        flex-direction: column;
    }
    .info-row {
        flex-direction: column;
    }
    .info-desc {
        text-align: center;
    }
    .info-icon {
        margin-bottom: 8px;
    }
}

@media (min-width:410px) and (max-width: 450px) {
    .mint-section-title {
        font-size: 30px;
        margin-bottom: 16px;
        text-align: center;
    }
    .wallet-connected-title {
        margin-top: 350px;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .section-content {
        padding: 0px 16rem;
    }
    .mint-section-title {
        font-size: 56px;
        margin-bottom: 12px;
    }
    .wallet-connected-title {
        margin-top: 0px;
    }
    .info-row {
        align-items: start;
    }
    .info-desc {
        text-align: center;
    }
}

.flexColumnCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flexRow {
    display: flex;
    align-items: center;
}

.flexRowCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* .mintContainer {
  margin-top: 60px;
} */

.connectBtn {
    background-color: #9cadff7a !important;
    border-radius: 20px !important;
    font-size: 28px !important;
    letter-spacing: 4px !important;
    padding: 0 0 !important;
    width: 300px !important;
}

.flexRowBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.messageContainer>.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root {
    background-color: white !important;
    margin-bottom: 0 !important;
}

.messageContainer>.MuiDialog-container>.MuiDialog-paperWidthSm {
    max-width: 700px !important;
}

.messageContainer>.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root::after {
    display: none !important;
}

.messageContainer>.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root>h2 {
    color: black !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 28px !important;
    display: block !important;
    align-items: center !important;
}

.dialogTitle {
    color: black !important;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 28px;
    letter-spacing: 4px;
}

.messageContainer>.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root {
    background-color: white !important;
    font-size: 38px;
    margin-top: -20px;
}

.messageContainer>.MuiDialog-container>.MuiPaper-root>.MuiDialogActions-root>button {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 28px;
}

.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root {
    background-color: #512da8 !important;
    margin-bottom: 0 !important;
}

.MuiPaper-rounded {
    border-radius: 8px !important;
}

.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root::after {
    display: none !important;
}

.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root>h2 {
    color: white !important;
    font-size: 1.25rem !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 600 !important;
    line-height: 1.6 !important;
    display: flex !important;
    align-items: center !important;
}

.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root>h2>button {
    color: white !important;
}

.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root {
    padding: 8px 0px !important;
    background-color: #212121 !important;
    padding: 10px 24px !important;
}

.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root>.MuiList-root>li {
    background-color: #212121 !important;
    box-shadow: none !important;
    padding: 8px 0px !important;
}

.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root>.MuiList-root>li>button {
    width: 100% !important;
    justify-content: space-between !important;
    color: white !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root>.MuiList-root>.MuiCollapse-root>.MuiCollapse-wrapper>.MuiCollapse-wrapperInner>ul>li>button {
    width: 100% !important;
    justify-content: space-between !important;
    color: white !important;
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.mintBtn {
    background-color: #1ee0af75 !important;
    border-radius: 20px !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 28px !important;
    color: white !important;
    width: auto;
    margin-top: -25px !important;
    letter-spacing: 4px !important;
    padding: 0 40px !important;
}

.successIcon {
    color: #31bf31 !important;
    margin-bottom: 6px;
    font-size: 52px !important;
}

.errorIcon {
    color: #ff7272 !important;
    margin-bottom: 6px;
    font-size: 52px !important;
}

.warningIcon {
    color: #e8bb3c !important;
    margin-bottom: 6px;
    font-size: 52px !important;
}

.infoIcon {
    color: #3cb8e8 !important;
    margin-bottom: 6px;
    font-size: 52px !important;
}

.subMessage {
    margin-top: -40px;
}

.successMsg {
    color: #31bf31 !important;
    margin-bottom: 34px;
    letter-spacing: 4px;
    text-align: center;
}

.errorMsg {
    color: #ff7272 !important;
    margin-bottom: 34px;
    letter-spacing: 4px;
    text-align: center;
}

.warningMsg {
    color: #e8bb3c !important;
    margin-bottom: 34px;
    letter-spacing: 4px;
    text-align: center;
}

.infoMsg {
    color: #3cb8e8 !important;
    margin-bottom: 34px;
    letter-spacing: 4px;
    text-align: center;
}